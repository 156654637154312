import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { server,cookies,local,parrot_key } from './components/environ';
import { init } from './components/parrot-js/parrot';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import {blue,pink,grey} from '@material-ui/core/colors/';

import ErrorBoundary   from  './ErrorBoundary';
import {theme} from './components/theme'

import { Notifications } from 'react-push-notification';
import DocsW from './components/vendor/Docs'
import BlogsW from './components/vendor/Blogs'
import BlogsPW from './components/vendor/BlogsP'


import DocsCW from './components/vendor/DocsC'
import DocsRW from './components/vendor/DocsR'


const UserVerified      = lazy(() => import('./components/vendor/signin/UserVerified'));
const ChangePassword    = lazy(() => import('./components/vendor/signin/ChangePassword'));
const StickyFooter      = lazy(() => import('./components/vendor/stickyfooter'));
const ErrorPage         = lazy(() => import('./components/vendor/signin/ErrorPage'));
const Privacy           = lazy(() => import('./components/vendor/signin/privacy'));
const Terms             = lazy(() => import('./components/vendor/signin/terms'));
const SignInForm        = lazy(() => import('./components/vendor/signin/SignInForm'));
const StaffSignInForm        = lazy(() => import('./components/vendor/signin/StaffSignInForm'));

const VendorView        = lazy(() => import('./components/vendor/dashboard/VendorView'));

{/*
        <Route  path="/" component={() => <DocsRW /> }/>   
*/}
function App() {
  init(parrot_key);
  console.log(window.location.host)
  return (
    
    <ThemeProvider theme={theme}>
    <Notifications />
    <Router>
    <Suspense fallback={<div></div>}>
    <Switch>

        
    
        <ErrorBoundary webaddress={window.location.href}>

        { window.location.host == "docs-front-build.onrender.com" || window.location.host.includes("localhost") ?
            <div>            
            <Route  path="/editor"                  component={() => <DocsW />}  />
            <Route  path="/blogeditor"              component={() => <BlogsW />}  />

            <Route  exact path="/docs/published"    component={() => <DocsRW /> }/>
            <Route  exact path="/blogs/published"    component={() => <BlogsPW /> }/>

            <Route  exact path="/" component={VendorView} /> 
            <Route  exact path="/signin" component={SignInForm} />
            <Route  exact path="/staffsignin" component={StaffSignInForm} />
            <Route  path="/dashboard"       component={VendorView} />
            <Route  path="/changepassword"      component={ChangePassword} />
            <Route  path="/userverified"  component={UserVerified} />
            <Route  path="/errorpage"  component={ErrorPage} />
            <Route  path="/terms" component={Terms} />
            <Route  path="/privacy" component={Privacy} />
            </div> :
            <div>
            <Route  exact path="/" component={() => <DocsRW /> }/>  
            </div>
        }
        
        </ErrorBoundary>
           
    </Switch>
    </Suspense>
    </Router>
    </ThemeProvider>
  );
}

export default App


//ReactDOM.render(<App />, document.querySelector('#app'));



import React from 'react';
import { Component,Fragment } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email } from '../environ';
import {translator} from  '../parrot-js/parrot';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SocialIcon } from 'react-social-icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Link from '@material-ui/core/Link';
import $ from 'jquery';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,convertFromRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,useTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontWeight: 400,
      fontSize: 33.6,
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: 'rgb(0,0,0)',
      letterSpacing : '0.5px'
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
      //  backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  aroot: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  blockSection: {
    backgroundColor : "#cf6b4c"
  },
  paper: {
    background: '#fdfdfe',
    color: '#3B454E'
  },
  listItemDone: {
    borderRadius: "1em",
    "&,&:focus": {
      backgroundColor: "#F6F6E8",
      textDecoration: "line-through"
    },
    "&:hover": {
      textDecoration: "line-through"
    }
  }

});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

function ListItemSidePane (props) {
  
  var {x,index} = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [rename, setRename] = React.useState(false);
  const [addSubItem, setAddSubItem] = React.useState(false);

  function handleClickAway (e) { 
    if (document.getElementById("input-3").value != "")
    props.createEditObject('edit',x.id,{ label : document.getElementById("input-3").value });
    setRename(false);
  }


  function handleClickAwayA (e) { 
    if (document.getElementById("input-4").value != "")
    props.createEditObject('create',null,{ label : document.getElementById("input-4").value });
    setAddSubItem(false);
  }

  return (
    <div>
    { !rename ?
     <ListItem id={"li" + x.id}  
       style={ props.index != props.selectedIndex ? { marginLeft : "2vw", borderRadius : "4px", width : props.width, transition: "color 270ms ease-out", color : "black"}
        : {backgroundColor : "#f0f1f2",  marginLeft : "2vw", borderRadius : "4px", width : props.width, transition: "color 270ms ease-out", color : "black"}
      }
       onMouseOver={e => { e.target.style.color = "rgb(70,48,235)" } }
       onMouseOut ={e => { e.target.style.color = "black" } } 
       onClick={e => {props.listItemSelected(index)}}
    >

    <Grid container direction="row" justify="space-between">
    <Typography variant={x.subitem == 0 ? "subtitle1" : "subtitle1"} style={x.subitem == 1 ? { fontSize:"16px", marginLeft : "4px", maxWidth : props.width, wordWrap: 'break-word', fontWeight : 400 } : {fontSize:"17px", maxWidth : props.width, wordWrap: 'break-word', fontWeight : 410 } } > {x.label} </Typography>
    </Grid>
    </ListItem> 
    :
    <ListItem  style={{marginLeft : "2vw", borderRadius : "4px", width : "17.5vw"}} >
    </ListItem>
    }
    </div>
  )
}






function DrawerList (props) {

  const [add,setAdd] = React.useState(false)

  function addItem (x) {
    if (x.value != "") props.addInList(x.value);
    setAdd(false);
  }
  
  function handleClickAway (e) { console.log("woof")
    addItem(document.getElementById("input-1")); 
    if (document.getElementById("input-2") != undefined) document.getElementById("input-2").style.opacity = 0
  }

  return (
  <List style={ !props.mobile ? { width : "22vw"} : { width : "80vw"} }>
  {
    props.list
    .map( (x,index) => {
      return(
        <div>
        { x.is_active == 1 &&
        <div style={ props.selectedIndex == index ? {fontWeight : 500} : {}} >
        <ListItemSidePane width={ !props.mobile ? "17.5vw" : "60vw" } selectedIndex={props.selectedIndex} createEditObject={props.createEditObject} x={x} index={index} listItemSelected={props.listItemSelected} />
        </div>
        }
        </div>
      )
    })
  }
  </List>
  );
}



function ShareDialog (props) {
  var {list,selectedIndex} = {...props}
  function link () {
    return window.location.href.split('?')[0] + '?i='+  selectedIndex + '&t=' + (list[selectedIndex].label).replace(/\s+/g, '-').toLowerCase()
  }
  return (
    <Dialog open={props.open} onClose={props.close} >
    <Box p={2}>
    <Grid container direction="column">
    <Button  style={{ margin : "10px"}} children={"Copy Link"}  onClick={() => {navigator.clipboard.writeText(link()); props.close(); } } />
    <Button  style={{ margin : "10px"}} onClick={e => {window.open(link(),"_blank" ); props.close()} } children={"Open in new tab"} />
    </Grid>
    </Box>
    </Dialog>
  )
}




const object_table = "tb_docs";

class DocsC extends Component {

  constructor (props) {
      super(props);
      this.state = { 
        editorState: EditorState.createEmpty(),
        contentState : EditorState.createEmpty().getCurrentContent(),
        html : "",
        showEditor : false,
        list : [],
        selectedIndex : 0,
        drawerOpen : false
      };
  }

  componentDidMount(){ 
   this.fetchList();

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.i != undefined)
      this.setState({selectedIndex : params.i})

  }

  componentDidUpdate(prevProps,prevState) {   
  }

  onEditorStateChange = (editorState) => { //console.log(this.state.editorState.getCurrentContent().getFirstBlock(),this.state.editorState)  
    this.setState({ editorState,});
    this.setState({ contentState : convertToRaw(editorState.getCurrentContent()) });
  };

  fetchList = () => { console.log("here")
      
    let url = server + '/public/user/object/listall?user_id=' + this.props.user_id + '&lc=' + this.props.lc;
        url = url + '&object=' + object_table

    fetch(url)
    .then(response => response.json())
    .then(jsondata => { 
      if(jsondata.status == "success") {

          var c = [];
          var d = {}; var e = null;
          jsondata.data.filter(x => {return (x.is_active == 1) }).forEach( x => {
            d[x.id] = x;
            if (x.prevptr == null)
              e = x.id;
          })

          jsondata.data.forEach(x => {
            if (e != null) { console.log("runnin")
              c.push(d[e]);
              e = d[e].nextptr;
            }
          })

          var a = [];
          c.forEach( x => { var b = {...x};
            b.content = x.content != null ? JSON.parse(x.content) : null;
            a.push(b);
          })
          console.log(a)
          this.setState({ list : a });
          if ( a.length > 0)
            this.setState({ contentState : a[this.state.selectedIndex].content, html : draftToHtml(a[this.state.selectedIndex].content).replace(/<p><\/p>/g,"<br/>") })
      }
    })
    .catch(err =>  { } )
  }

 

  addInList = (x) => {console.log("addInList",  x)
    this.createEditObject('create',null,{ label : x, content : null })
    this.setState({ selectedIndex : this.state.list.length})
  }



  listItemSelected = (x) => { 

    var li = document.getElementById("li" +  this.state.list[this.state.selectedIndex].id);
    var lin = document.getElementById("li" +  this.state.list[x].id);


    if ( li != undefined)
    { 
      li.style.color = "#404040"; li.style.backgroundColor = null; 
    }    
    this.setState({ selectedIndex : x })
    lin.style.color = "black"; lin.style.backgroundColor = "#f0f1f2";   
    
    if (this.state.list[x].content != null){
      this.setState({ contentState : this.state.list[x].content})
      this.setState({html : draftToHtml(this.state.list[x].content).replace(/<p><\/p>/g,"<br/>")})
    }
    else
      this.setState({html : "", contentState : convertToRaw(EditorState.createEmpty().getCurrentContent())  })

    this.setState({drawerOpen : false})
  }



  render() {

  const { classes } = this.props;
  const { editorState,contentState, showEditor,html,list,selectedIndex } = this.state;
  console.log(selectedIndex,list)
   return(
    <ThemeProvider theme={theme}>
    <Container style={{padding : 0}}>
   
    <AppBar style={{zIndex: 1400,borderBottom : "1px solid lightgrey",padding : "0px 20px"}} alignItems="center" elevation={0} color="#FFFFFF" >
    <Grid container direction="row" justify="space-between" alignItems="center">
    <Grid item >
    <Typography style={{fontWeight : "500", display : "inline"}} variant="h3" children={ "PlumQR" } />
    <Typography style={{fontWeight : "400", fontSize : "20px", padding : "8px 16px", backgroundColor : "#f0f1f2", borderRadius : "4px", display : "inline", marginLeft : "20px" }} variant="h3" children={ "Docs" }/>
    </Grid>
    <Grid item>
    <ShareOutlinedIcon button
      style={{ opacity : 0.9, fontSize : "28px", padding : "10px"}} 
      id="shareicon"
      onMouseOver={e => { e.target.style.color = "rgb(70,48,235)"; } }
      onMouseLeave ={e => { e.target.style.color = "black"; } } 
      onClick={e => this.setState({sharedialogOpen : true}) } />
    </Grid> 
    </Grid>
    </AppBar>
    
    <Hidden xsDown implementation="css">
    <Container style={{ padding : 0}}>
    
    <Drawer variant="permanent" classes={{ paper : classes.paper }}  >
    <Box p={5}/>
    <DrawerList list={list} mobile={false} addInList={this.addInList} classes={classes} listItemSelected={this.listItemSelected} selectedIndex={selectedIndex} />
    </Drawer>
    <Grid container direction="column" alignItems="flex-end" style={{marginLeft : "18vw", width : "70vw"}}>
    <div style={{ height : "50vh", marginTop : "2vh", width : "70vw"  }} >
    <Box m={10}/>
    <Typography style={{ color : "#393939"}}>
    { parse(html.replace(/<p /g,'<p style="margin : 30px 0px"')) }
    </Typography>
    </div>
    </Grid>
    </Container> 
    </Hidden>

    <Hidden smUp implementation="js">

    <Drawer variant="temporary" open={this.state.drawerOpen} classes={{ paper : classes.paper }}  >
    <Box pt={14}/>
    <DrawerList list={list} mobile={true} addInList={this.addInList} classes={classes} listItemSelected={this.listItemSelected} selectedIndex={selectedIndex} />
    </Drawer>
   
    <Box pt={7}/>
    
    <AppBar style={{zIndex: 1400, borderBottom : "1px solid lightgrey", height : "44px", position : "relative",padding : "8px 20px" }} elevation={0} color = "#ffffff">
    { list.length > 0 &&
    <Typography onClick={e => this.setState({drawerOpen : !this.state.drawerOpen})} variant="subtitle1" children={'\u{2022}  ' + list[selectedIndex].label}/>}
    </AppBar>
    
    <Container>
    <Typography style={{ color : "#393939"}}>
    { parse(html.replace(/<p /g,'<p style="margin : 30px 0px"')) }
    </Typography>
    </Container>

    </Hidden>


    <ShareDialog list={list} selectedIndex={selectedIndex} open={this.state.sharedialogOpen} close={e => {this.setState({ sharedialogOpen : false})}} />

    </Container>
    </ThemeProvider>

    );
  }
}

const DocsCW = withStyles(useStyles)(DocsC);
export default DocsCW
import React from 'react';
import { Component,Fragment } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import Appbar from '@material-ui/core/Appbar';

import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email } from '../environ';
import {translator} from  '../parrot-js/parrot';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SocialIcon } from 'react-social-icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Link from '@material-ui/core/Link';
import $ from 'jquery';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,convertFromRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';


import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,useTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

const queryString = require('query-string');

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontWeight: 400,
      fontSize: 33.6,
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: 'rgb(0,0,0)',
      letterSpacing : '0.5px'
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
      //  backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  aroot: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  blockSection: {
    backgroundColor : "#cf6b4c"
  },
  paper: {
    background: '#F5F7F9',
    color: '#3B454E'
  },
  listItemDone: {
    borderRadius: "1em",
    "&,&:focus": {
      backgroundColor: "#F6F6E8",
      textDecoration: "line-through"
    },
    "&:hover": {
      textDecoration: "line-through"
    }
  }

});




const object_table = "tb_blogs";

class X extends Component {

  constructor (props) {
      super(props);
      this.state = { 
        editorState: EditorState.createEmpty(),
        contentState : EditorState.createEmpty().getCurrentContent(),
        html : "",
        showEditor : false,
        list : [],
        selectedIndex : 0,
        title : null
      };
  }

  componentDidMount(){ 
   

   var parsed = queryString.parse( window.location.search);
   console.log(parsed)

   this.fetchList(parsed.id);

  }

  componentDidUpdate(prevProps,prevState) {   
  }



  fetchList = (id) => { 
      
    let url = server + '/user/object/listall?access_token=' + cookies.get('/user/access_token') + '&lc=' + this.props.lc;
        url = url + '&object=' + object_table

    fetch(url)
    .then(response => response.json())
    .then(jsondata => {  
      if(jsondata.status == "success") { 
          var a = [];

          jsondata.data
          .filter(x => { return id == undefined ? true : (x.id == id) })
          .filter(x => {return (x.is_active == 1) })
          .forEach( x => {
            var b = {...x};
            b.content = x.content != null ? JSON.parse( (new TextDecoder("utf-8")).decode(new Uint8Array(x.content.data)) ) : null;
            a.push(b);
          })
          
          this.setState({ list : a });
          if ( a.length > 0)
            this.setState({ title : a[this.state.selectedIndex].title, html : draftToHtml(a[this.state.selectedIndex].content).replace(/<p><\/p>/g,"<br/>") })
      }
    })
    .catch(err =>  { } )
  }









  render() {

  const { classes } = this.props;
  const { editorState,contentState, showEditor,html,list,selectedIndex } = this.state;

  console.log(window.location)

   return(
    <div style={{ backgroundColor : "white"}}>
    
    {/*
    <Head>
    <title>{blog_data[4].title}</title>
    <meta property="og:title" content={blog_data[4].title} key="title" />
    <meta name="description" content={blog_data[4].meta} key="description" />
    <meta name="og:description" content={blog_data[4].meta} key="ogdescription" />
    <meta property="og:image" content={blog_data[4].picture} key="ogimage" />
    </Head>*/}

    <Appbar position="static" style={{ background : "#0b1c4d"}} elevation={0}>
    <Container component="main" style={{  maxWidth : "960px"}}>
    <a href="https://web.plumqr.com"><img src="https://i.ibb.co/cXVK0GN/Screenshot-2020-12-04-at-1-42-28-AM.png" alt="plumqr-logo"  border="0" 
    style={{marginBottom : "3px", marginTop : "5px"}} 
    /></a>
    </Container>
    </Appbar>

    <Container  style={{ background : "white", maxWidth : "960px"}}>
    <CssBaseline />
    <Box p={2} />
    {this.state.title && 
      <h1 style={{textAlign : "center"}} >{ this.state.list[this.state.selectedIndex].title  }</h1>}
    <Box p={3} />
      <div style={{ color : "#393939", marginLeft : "30px", minHeight : "100vh"}}>
      { parse(html.replace(/<p /g,'<p style="margin : 30px 0px"') ) }
      </div>
      <Box p={6} />
    </Container>
    
    </div>

    );
  }
}

const BlogsPW = withStyles(useStyles)(X);
export default BlogsPW
import React from 'react';
import { Component,Fragment } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';

import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email } from '../environ';
import {translator} from  '../parrot-js/parrot';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SocialIcon } from 'react-social-icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Link from '@material-ui/core/Link';
import $ from 'jquery';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,convertFromRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,useTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontWeight: 400,
      fontSize: 33.6,
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: 'rgb(0,0,0)',
      letterSpacing : '0.5px'
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
      //  backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const useStyles = theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  aroot: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  blockSection: {
    backgroundColor : "#cf6b4c"
  },
  paper: {
    background: '#F5F7F9',
    color: '#3B454E'
  },
  listItemDone: {
    borderRadius: "1em",
    "&,&:focus": {
      backgroundColor: "#F6F6E8",
      textDecoration: "line-through"
    },
    "&:hover": {
      textDecoration: "line-through"
    }
  }

});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

function ListItemSidePane (props) {
  
  var {x,index} = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [rename, setRename] = React.useState(false);
  const [addSubItem, setAddSubItem] = React.useState(false);

  var e3 = document.getElementById("input-3");
  var l1 = document.getElementById("li" + x.id);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function handleRename  () {
    setRename(true); setAnchorEl(null);
  }

  function handleSubItem () {
    setAnchorEl(null);
    var d = {}; var e = null;
    var a = props.list.filter(x => {return (x.is_active == 1) });
    a.forEach( x => {
      d[x.id] = x;
    });
    var f = true
    a.forEach(z => { 
      if (f){
      if (d[x.id].nextptr == null) 
        f = false;
      else
      {
        if (d[d[x.id].nextptr].subitem == 0)
          f = false
        else
          x = d[x.nextptr];
      }
    }
    })
    

    props.createEditObject('create',null,{ label : "Add Sub Item", prevptr : x.id, nextptr : x.nextptr, subitem : 1 },false,(err,resultA) => {
      props.createEditObject('edit',x.nextptr,{ prevptr : resultA.insertId },false,(err,result) => {
        props.createEditObject('edit',x.id,{ nextptr : resultA.insertId  },true);
      });
    });
  } 

  function doRename() { var e3 = document.getElementById("input-3");
    props.createEditObject('edit',x.id,{ label : e3.value });
    setRename(false);
  }

  function doDelete() { 
    props.createEditObject('edit',x.id,{ is_active : 0,prevptr : null, nextptr : null },false,(err,result) => {
      props.createEditObject('edit',x.prevptr,{ nextptr : x.nextptr },false,(err,result) => {
        props.createEditObject('edit',x.nextptr,{ prevptr : x.prevptr },true);
    });
    });
    setRename(false); setAnchorEl(null);
  }



  function handleClickAway (e) { var e3 = document.getElementById("input-3");
    if (e3.value != "")
      props.createEditObject('edit',x.id,{ label : e3.value });
    setRename(false);
  }


  return (
    <div>
    { !rename ?
     <ListItem id={"li" + x.id}  
     style={{marginLeft : "2vw", borderRadius : "4px", width : "17.5vw", transition: "color 270ms ease-out"}}
     onMouseOver={e => { l1.style.color = "black" } }
     onMouseOut ={e => { l1.style.color = "#404040" } } 
     onClick={e => {props.listItemSelected(index)}}
    >
    <Grid container direction="row" justify="space-between">
    <Typography   variant={x.subitem == 0 ? "subtitle1" : "body2"} style={x.subitem == 1 ? { marginLeft : "4px", maxWidth : "12vw", wordWrap: 'break-word' } : {maxWidth : "12vw", wordWrap: 'break-word'}} > {x.label} </Typography>
    <MoreHorizIcon id={"more" + x.id} style={{ opacity : 0}} 
      onClick={e => {handlePopoverOpen(e); props.listItemSelected(index);} } 
    />
    </Grid>
    <Popover open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }} onClose={handlePopoverClose}>
        <List style={{ margin : "2px",padding : "0px"}} >
        { x.subitem == 0 && 
        <ListItem style={{borderRadius : "4px"}} button children={"Add Subitem"} onClick={e => handleSubItem()} />}
        <ListItem style={{borderRadius : "4px"}} button children={"Rename"} onClick={e => handleRename()} />
        <ListItem style={{borderRadius : "4px"}} button children={"Delete"} onClick={e => doDelete() }/>
        </List>
    </Popover>
    </ListItem> 
    :
    <ListItem  style={{marginLeft : "2vw", borderRadius : "4px", width : "17.5vw"}} >
    <ClickAwayListener onClickAway={e => {handleClickAway(e)} }>  
    <InputBase id="input-3" defaultValue={x.label} autoFocus onKeyDown={e => { if (e.keyCode == 13) { doRename(); } } } style={{ opacity : 0.8}}  />
    </ClickAwayListener>
    </ListItem>
    }
    </div>
  )
}






function DrawerList (props) {

  const [add,setAdd] = React.useState(false)

  var l = props.list.filter(x => { return (x.is_active == 1);})

  var e2 = document.getElementById("input-2");
  var e1 = document.getElementById("input-1");


  function addItem () { 
    var x = document.getElementById("input-1");
    var prevptr = null; 
    if (l.length > 0) prevptr = l[l.length-1].id;
    if (x.value != "") props.addInList(x.value,prevptr);
    setAdd(false);
  }
  
  function handleClickAway (e) { 
    addItem(); 
    if (e2 != undefined) e2.style.opacity = 0
  }

  return (
  <List style={{ width : "20vw"}}>
  { // all items on the side which are active
    props.list
    .map( (x,index) => {
      return(
        <div>
        { x.is_active == 1 &&
        <div style={ props.selectedIndex == index ? {fontWeight : 500} : {}} >
        <ListItemSidePane list={props.list} createEditObject={props.createEditObject} x={x} index={index} listItemSelected={props.listItemSelected} />
        </div>
        }
        </div>
      )
    })
  }
  <ListItem dense // pure hove in hover out
    onMouseOver={e => {  if ( e2 != undefined) e2.style.opacity = 0.8} }
    onMouseOut ={e => {  if ( e2 != undefined) e2.style.opacity = 0} }
  >
  <div style={{ paddingLeft : "2vw"}} >
  { !add ? // additional item to add
  <InputBase id="input-2" placeholder={"add a label"} onClick={e => setAdd(true)} style={{ opacity : 0.8}}  />
  :
  <ClickAwayListener onClickAway={e => {handleClickAway(e)} }>  
  <InputBase id="input-1" autoFocus onKeyDown={e => { if (e.keyCode == 13) addItem(); } } />
  </ClickAwayListener>  
  }
  </div>
  </ListItem>
  <Box m={16}/>
  </List>
  );
}








const object_table = "tb_docs";

class Docs extends Component {

  constructor (props) {
      super(props);
      this.state = { 
        editorState: EditorState.createEmpty(),
        contentState : EditorState.createEmpty().getCurrentContent(),
        html : "",
        showEditor : false,
        list : [],
        selectedIndex : 0,
      };
  }

  componentDidMount(){ 
   this.fetchList();
  }

  componentDidUpdate(prevProps,prevState) {   
  }

  onEditorStateChange = (editorState) => { 
    this.setState({ editorState,});
    this.setState({ contentState : convertToRaw(editorState.getCurrentContent()) });
  };

  onContentStateChange = (contentState) => {
    this.setState({
      contentState,
    });
  };

  fetchList = () => { 
      
    let url = server + '/user/object/listall?access_token=' + cookies.get('/user/access_token') + '&lc=' + this.props.lc;
        url = url + '&object=' + object_table

    fetch(url)
    .then(response => response.json())
    .then(jsondata => {  
      if(jsondata.status == "success") { var c = [];
          var d = {}; var e = null; 
          jsondata.data.filter(x => {return (x.is_active == 1) }).forEach( x => {
            d[x.id] = x; 
            if (x.prevptr == null)
              e = x.id;
          })


          jsondata.data.forEach(x => {
            if (e != null) { 
              c.push(d[e]);
              e = d[e].nextptr;
            }
          })
           
          var a = [];

          c.forEach( x => { var b = {...x};
            b.content = x.content != null ? JSON.parse(x.content) : null;
            a.push(b);
          })

          this.setState({ list : a });
          if ( a.length > 0)
            this.setState({ contentState : a[this.state.selectedIndex].content, html : draftToHtml(a[this.state.selectedIndex].content).replace(/<p><\/p>/g,"<br/>") })
      }
    })
    .catch(err =>  { } )
  }

  createEditObject = (opr,object_id,object_data,fetchlist,callback) => { 

    var data = { object_data : object_data, object_type : object_table, object_id : object_id, opr : opr};
    
    data.access_token = cookies.get('/user/access_token');
    data.lc           = this.props.lc;
    
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };
    let url = server + '/user/object/crud'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success") { 
        if (fetchlist == undefined || fetchlist == true )
          this.fetchList(); 
        if (callback != undefined)
          return callback(null,jsondata.data);
      }
      else this.props.displaySM("Some Error Occured") 
    });
  }

  addInList = (x,prevptr) => {
  this.createEditObject('create',null,{ label : x, content : null, prevptr : prevptr},(prevptr == null),(err,result) => {
      if (prevptr != null)
      this.createEditObject('edit',prevptr,{nextptr : result.insertId},true)
   })
  this.setState({ selectedIndex : this.state.list.length})
  }



  listItemSelected = (x) => { 

    if (this.state.showEditor == true) return false;

    var li = this.state.list[this.state.selectedIndex] != undefined ? document.getElementById("li" +  this.state.list[this.state.selectedIndex].id) : undefined
    var mi = this.state.list[this.state.selectedIndex] != undefined ? document.getElementById("more" + this.state.list[this.state.selectedIndex].id) : undefined

    var lin = document.getElementById("li" +  this.state.list[x].id);
    var min = document.getElementById("more" +  this.state.list[x].id);


    if ( li != undefined)
    { 
      li.style.color = "#404040"; li.style.backgroundColor = null; 
      mi.style.opacity=0; 
    }    
    this.setState({ selectedIndex : x })
    lin.style.color = "black"; lin.style.backgroundColor = "lightgrey"; min.style.opacity=0.7;     
    
    if (this.state.list[x].content != null){
      this.setState({ contentState : this.state.list[x].content})
      this.setState({html : draftToHtml(this.state.list[x].content).replace(/<p><\/p>/g,"<br/>")})
    }
    else
      this.setState({html : "", contentState : convertToRaw(EditorState.createEmpty().getCurrentContent())  })
  }




  render() {

  const { classes } = this.props;
  const { editorState,contentState, showEditor,html,list,selectedIndex } = this.state;


  console.log(this.state.html)
  //console.log(draftToHtml(contentState).replace(/<p><\/p>/g,"<br/>"))


   return(
    <ThemeProvider theme={theme}>
    <Container>

    <Drawer variant="permanent" open={true}       classes={{ paper : classes.paper }} >
    <Typography style={{ paddingBottom : "2vh", paddingTop : "2vh", paddingLeft : "3vw"}} children="PlumQR Docs" variant="h3"/>
    <DrawerList createEditObject={this.createEditObject} list={list} addInList={this.addInList} classes={classes} listItemSelected={this.listItemSelected} selectedIndex={selectedIndex} />
    </Drawer>

    <Grid container direction="column" alignItems="flex-end" style={{marginLeft : "18vw", width : "70vw"}}>
    { !showEditor ?
      <Button variant="inline" style={{ marginTop : "3vh", padding :"8px", width : "50px"}} children={"Edit"} onClick={e => this.setState({showEditor : true, })}/>
      :
      <Button variant="inline" style={{ marginTop : "3vh", padding : "8px",  width : "50px"}} children={"Save"} 
            onClick={e => { console.log(draftToHtml(contentState)); console.log(draftToHtml(contentState))
                            this.createEditObject('edit',list[selectedIndex].id,{ content : JSON.stringify(contentState) },true);
                            this.setState({html : draftToHtml(contentState).replace(/<p><\/p>/g,"<br/>")});
                            this.setState({showEditor : false}); 
                          } 
                    }
    />
    }
    <div style={{ height : "50vh", marginTop : "2vh", width : "70vw"  }} >
    { showEditor ? 
    <Editor
     // defaultContentState={contentState}
      initialContentState={contentState}
    //  contentState={contentState}
      onContentStateChange={this.onContentStateChange}
     // onEditorStateChange={this.onEditorStateChange}
    />
    :
    <div style={{ color : "#393939", marginLeft : "30px"}}>
    { parse(html.replace(/<p /g,'<p style="margin : 30px 0px"') ) }
    </div>
    }
    </div>
    </Grid>
    
    </Container>
    </ThemeProvider>

    );
  }
}

const DocsW = withStyles(useStyles)(Docs);
export default DocsW
import React from 'react';
import { Component,Fragment } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';

import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email } from '../environ';
import {translator} from  '../parrot-js/parrot';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import DocsCW from './DocsC'



import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,useTheme,
  createMuiTheme,
} from '@material-ui/core/styles';

const tb_object = "tb_weburls"

class DocsRW extends Component {

  constructor (props) {
      super(props);
      this.state = { 
        user_id : null,
        msg: ""
      };
  }

  componentDidMount(){ 
   this.fetchList();
  }

  componentDidUpdate(prevProps,prevState) {   
  }

  fetchList = (indicator) => {
      
    let url = server + '/public/weburls?weburl=' + window.location.host

    fetch(url)
    .then(response => response.json())
    .then(jsondata => { 
    if(jsondata.status == "success") {

      var y = jsondata.data.find(x => {return (x.weburl == window.location.host && x.type == "doc") } )
      if (y != undefined)
        this.setState({user_id : y.user_id})
      else
        this.setState({msg : "Some error in setup"})
    }
    });
  }

  render() {

   return(
      <div>
      { this.state.user_id != null ?
        <DocsCW user_id={this.state.user_id} /> :
        <div children={this.state.msg}/> 
      }
      </div>
    );
  }
}

export default DocsRW
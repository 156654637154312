import React from 'react';
import { Component,Fragment } from 'react'

import useMediaQuery from '@material-ui/core/useMediaQuery';

import Container from '@material-ui/core/Container';
import Checkbox from '@material-ui/core/Checkbox';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import MuiAlert from '@material-ui/lab/Alert';
import CardMedia from '@material-ui/core/CardMedia';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popover from '@material-ui/core/Popover';
import DialogTitle from '@material-ui/core/DialogTitle';

import Divider from '@material-ui/core/Divider';

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';

import { server,cookies,site_name,domain_name,subscriptionPlans,signUpContent,help_email } from '../environ';
import {translator} from  '../parrot-js/parrot';
import { con,cur_con,lang,getLanguageName,getCountryName,getCurrencyName,getCurrencySymbol,getCountryList,getLanguageList } from '../countrycurrencies';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { SocialIcon } from 'react-social-icons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Link from '@material-ui/core/Link';
import $ from 'jquery';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw,convertFromRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import parse from 'html-react-parser';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import {
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,useTheme,
  createMuiTheme,
} from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
const queryString = require('query-string');


let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: 40,
    },
    h2: {
      fontWeight: 400,
      fontSize: 33.6,
      color: 'rgb(0,0,0)'
    },
    h3: {
      fontSize: 24,
    },
    h4: {
      fontSize: 16,
      fontWeight: 400,
      color: 'rgb(0,0,0)',
      letterSpacing : '0.5px'
    },
  },
  shape: {
    borderRadius: 4,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
      //  backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const blog_data = [
{ name : "Alisha J", 
  date : "Oct 14", 
  subLabel : "2 min", 
  link : "https://blog.plumqr.com/qr-menus-for-room-service-in-hotels", 
  title : "QR Menus For Room Service In Hotels - 5 Benefits",
  meta : "Know why using QR menus for enabling contactless room service in hotels will boost your revenue during Covid 2020. Hotels can open faster, keep guests safe & earn additional revenue. ",
  h1 : "QR Menus For Room Service In Hotels - 5 Benefits", 
  picture : "https://i.ibb.co/F8L0SWF/Screenshot-2020-12-08-at-4-12-00-PM.png" },

{ name : "Mark R", date : "Oct 28", subLabel : "2 min",
  link : "https://blog.plumqr.com/build-food-pickup-and-delivery-app-for-your-restaurant", 
  title : "QR Codes vs Mobile Apps For Order Takeaway & Delivery In Restaurants", 
  meta : "Use QR codes or build mobile app for order takeaway & delivery in restaurants - Pros, Cons, Investment needed & Return on Investment.",
  h1 : "QR Codes vs Mobile Apps For Order Takeaway & Delivery In Restaurants",
  picture : "https://i.ibb.co/MCmTF5b/Screenshot-2020-12-08-at-4-12-07-PM.png" },


{ name : "Mark R", date : "Dec 08", subLabel : "5 min", 
  link : "https://blog.plumqr.com/best-contactless-menu-comparison-for-restaurants-and-hotels-during-covid-2020", 
  title : "5 Best Contactless Menus- Pros, Cons, Pricing- For Restaurants & Hotels During COVID", 
  meta : "Find pros, cons & prices of the best contactless menus for restaurants & hotels during Covid 2020 to suit your dine-in, takeaway, curbside & room service needs.",
  h1 : "5 Best Contactless Menus- Pros, Cons, Pricing- For Restaurants & Hotels During COVID",
  picture : "https://i.ibb.co/fvSs7pY/Whats-App-Image-2020-12-09-at-5-05-07-AM.jpg" },

{ name : "Alisha", date : "Dec 21", subLabel : "5 min", 
  link : "https://blog.plumqr.com/top-8-contactless-menus-for-restaurants-cost-vs-features", 
  title : "Top 8 Contactless Menus For Restaurants- Cost vs Features", 
  meta : "Features vs Cost analysis for the top 8 contactless menus for restaurants & hotels. Best COVID menus in 2020 to suit your dine-in, takeaway, curbside & room service needs.",
  h1 : "Top 8 Contactless Menus For Restaurants- Cost vs Features",
  picture : "https://i.ibb.co/Wgy66kv/Whats-App-Image-2020-12-17-at-12-36-18-PM.jpg" },

]




const useStyles = theme => ({

  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  aroot: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  blockSection: {
    backgroundColor : "#cf6b4c"
  },
  paper: {
    background: '#F5F7F9',
    color: '#3B454E'
  },
  listItemDone: {
    borderRadius: "1em",
    "&,&:focus": {
      backgroundColor: "#F6F6E8",
      textDecoration: "line-through"
    },
    "&:hover": {
      textDecoration: "line-through"
    }
  }

});



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

function ListItemSidePane (props) {
  
  var {x,index} = props

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [rename, setRename] = React.useState(false);

  var e3 = document.getElementById("input-3");
  var l1 = document.getElementById("li" + x.id);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function handleRename  () {
    setRename(true); setAnchorEl(null);
  }


  function doRename() { var e3 = document.getElementById("input-3");
    props.createEditObject('edit',x.id,{ title : e3.value });
    setRename(false);
  }

  function doDelete() { 
    props.createEditObject('edit',x.id,{ is_active : 0 },false,(err,result) => {
      console.log("deleted")
    });
    setRename(false); setAnchorEl(null);
  }



  function handleClickAway (e) { var e3 = document.getElementById("input-3");
    if (e3.value != "")
      props.createEditObject('edit',x.id,{ title : e3.value });
    setRename(false);
  }


  return (
    <div>
    { !rename ?
     <ListItem id={"li" + x.id}  
     style={{marginLeft : "2vw", borderRadius : "4px", width : "17.5vw", transition: "color 270ms ease-out"}}
     onMouseOver={e => { l1.style.color = "black" } }
     onMouseOut ={e => { l1.style.color = "#404040" } } 
     onClick={e => {props.listItemSelected(index)}}
    >
    <Grid container direction="row" justify="space-between">
    <Typography   variant={x.subitem == 0 ? "subtitle1" : "body2"} style={x.subitem == 1 ? { marginLeft : "4px", maxWidth : "12vw", wordWrap: 'break-word' } : {maxWidth : "12vw", wordWrap: 'break-word'}} > {x.title} </Typography>
    <MoreHorizIcon id={"more" + x.id} style={{ opacity : 0}} 
      onClick={e => {handlePopoverOpen(e); props.listItemSelected(index);} } 
    />
    </Grid>
    <Popover open={open} anchorEl={anchorEl} anchorOrigin={{ vertical: 'bottom', horizontal: 'center', }} transformOrigin={{ vertical: 'top', horizontal: 'center', }} onClose={handlePopoverClose}>
        <List style={{ margin : "2px",padding : "0px"}} >
        <ListItem style={{borderRadius : "4px"}} button children={"Rename"} onClick={e => handleRename()} />
        <ListItem style={{borderRadius : "4px"}} button children={"Edit"} onClick={e =>   {props.handleEdit(e); handlePopoverClose();} } />
        <ListItem style={{borderRadius : "4px"}} button children={"Delete"} onClick={e => doDelete() }/>
        </List>
    </Popover>
    </ListItem> 
    :
    <ListItem  style={{marginLeft : "2vw", borderRadius : "4px", width : "17.5vw"}} >
    <ClickAwayListener onClickAway={e => {handleClickAway(e)} }>  
    <InputBase id="input-3" defaultValue={x.title} autoFocus onKeyDown={e => { if (e.keyCode == 13) { doRename(); } } } style={{ opacity : 0.8}}  />
    </ClickAwayListener>
    </ListItem>
    }
    </div>
  )
}






function DrawerList (props) {

  const [add,setAdd] = React.useState(false)

  var l = props.list.filter(x => { return (x.is_active == 1);})

  var e2 = document.getElementById("input-2");
  var e1 = document.getElementById("input-1");


  function addItem () { 
    var x = document.getElementById("input-1");
    if (x.value != "") props.addInList(x.value);
    setAdd(false);
  }
  
  function handleClickAway (e) { 
    addItem(); 
    if (e2 != undefined) e2.style.opacity = 0
  }

  return (
  <List style={{ width : "20vw"}}>
  { // all items on the side which are active
    props.list
    .map( (x,index) => {
      return(
        <div>
        { x.is_active == 1 &&
        <div style={ props.selectedIndex == index ? {fontWeight : 500} : {}} >
        <ListItemSidePane handleEdit={props.handleEdit} list={props.list} createEditObject={props.createEditObject} x={x} index={index} listItemSelected={props.listItemSelected} />
        </div>
        }
        </div>
      )
    })
  }
  <ListItem dense // pure hove in hover out
    onMouseOver={e => {  if ( e2 != undefined) e2.style.opacity = 0.8} }
    onMouseOut ={e => {  if ( e2 != undefined) e2.style.opacity = 0} }
  >
  <div style={{ paddingLeft : "2vw"}} >
  { !add ? // additional item to add
  <InputBase id="input-2" placeholder={"add a label"} onClick={e => setAdd(true)} style={{ opacity : 0.8}}  />
  :
  <ClickAwayListener onClickAway={e => {handleClickAway(e)} }>  
  <InputBase id="input-1" autoFocus onKeyDown={e => { if (e.keyCode == 13) addItem(); } } />
  </ClickAwayListener>  
  }
  </div>
  </ListItem>
  <Box m={16}/>
  </List>
  );
}

const object_table = "tb_blogs";
const tb_table = "tb_blogs"
const object_name = "Blog Properties"


function uploadImageCallBack(file) {

 return new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve( { data: { link: "https://i.ibb.co/1sC1KB2/Plum-Images-17-2.jpg" } } );
  }, 5000);

  });
}

const embedVideoCallBack = (link) =>{
        if (link.indexOf("youtube") >= 0){
            link = link.replace("watch?v=","embed/");
            link = link.replace("/watch/", "/embed/");
            link = link.replace("youtu.be/","youtube.com/embed/");
        }
        return link
}

const formLabels = [ 
      {l : "Title", id : "title", placeholder : "My Blog", type : "text", fullWidth : true},
      {l : "Author", id : "author", placeholder : "John Quinn", type : "text"},
      {l : "Sub Label", id : "sublabel", placeholder : "3 min", type : "text"}, 
      {l : "Meta", id : "meta", placeholder : "this page is about this", type : "text",fullWidth : true},
      {l : "Picture Link", id : "picture", placeholder : "page image", type : "text",fullWidth : true},
      {l : "Page URL", id : "suburl", placeholder : "this-blog-page", type : "text",fullWidth : true},


//      {l : "Enable Filtering", id : "filter", type : "checkbox"},
//      {l : "Icon", id : "image", type : "image"},
]


function CrudDialog (props) { 


  var row = props.modify_item_data;
  var edit =  props.modify_item ? true : false;
  var actionText = props.modify_item ? "Edit" : "Add"

  return (
    <Dialog fullWidth={true} maxWidth = {'xs'} open={props.crudDialogOpen} onClose={props.handleCrudDialogClose}>
    <DialogTitle children={ actionText + " " + object_name } />
    <DialogContent>
    { formLabels.map( x => {
      return (
        <div style={{ marginBottom : "8px"}}>
        { x.type == "text" &&
          <TextField
            multiline
            margin="dense"
            id={"form-" + x.id}
            label={x.l}
            type={x.type}
            placeholder={x.placeholder}
            defaultValue = {edit ? row[x.id] : null}
            style={{width : x.width}}
          />
        }
        </div>);
      })
    }         
    </DialogContent>
    <DialogActions>
    <Box m={0.25}/>
    { edit  && !row.is_active  &&
    <Button color="primary" onClick={e => props.crudObject('delete',row.id) } children={'delete'} />
    }
    <div style={{flex: '1 0 0'}} />
    <Button onClick={props.handleCrudDialogClose} color="primary" children={'Cancel'} />
    <Button color="primary" onClick={ e => {edit ? props.crudObject('edit',tb_table,row.id,{}) : props.crudObject('create',null); }  } children={actionText} /> 
    </DialogActions>
    </Dialog>
  );
}







class X extends Component {

  constructor (props) {
      super(props);
      this.state = { 
        editorState: EditorState.createEmpty(),
        contentState : EditorState.createEmpty().getCurrentContent(),
        html : "",
        showEditor : false,
        list : [],
        selectedIndex : 0,
        crudDialogOpen : false,
        modify_item_data : {},
      };
  }

  componentDidMount(){ 
   this.fetchList();
  }

  componentDidUpdate(prevProps,prevState) {   
  }

  onEditorStateChange = (editorState) => { 
    this.setState({ editorState,});
    this.setState({ contentState : convertToRaw(editorState.getCurrentContent()) });
  };

  onContentStateChange = (contentState) => {
    this.setState({
      contentState,
    });
  };

  fetchList = () => { 
      
    let url = server + '/user/object/listall?access_token=' + cookies.get('/user/access_token') + '&lc=' + this.props.lc;
        url = url + '&object=' + object_table

    fetch(url)
    .then(response => response.json())
    .then(jsondata => {  
      if(jsondata.status == "success") { 
          var a = [];
          jsondata.data.filter(x => {return (x.is_active == 1) }).forEach( x => { //console.log(x)
            var b = {...x};

            b.content = x.content != null ? JSON.parse( (new TextDecoder("utf-8")).decode(new Uint8Array(x.content.data)) ) : null;
            a.push(b)
          })
          
          this.setState({ list : a });
          if ( a.length > 0)
            this.setState({ contentState : a[this.state.selectedIndex].content, html : draftToHtml(a[this.state.selectedIndex].content).replace(/<p><\/p>/g,"<br/>") })
      }
    })
    .catch(err =>  { } )
  }





  createEditObject = (opr,object_id,object_data,fetchlist,callback) => { 

    var data = { object_data : object_data, object_type : object_table, object_id : object_id, opr : opr};
    
    data.access_token = cookies.get('/user/access_token');
    data.lc           = this.props.lc;
    
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };
    let url = server + '/user/object/crud'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success") { 
        if (fetchlist == undefined || fetchlist == true )
          this.fetchList(); 
        if (callback != undefined)
          return callback(null,jsondata.data);
      }
    //  else this.props.displaySM("Some Error Occured") 
    });
  }

  addInList = (x) => {
    this.createEditObject('create',null,{ title : x, content : null, weburl : "plumqr.com"},true,(err,result) => {
          console.log("added")
    })
    this.setState({ selectedIndex : this.state.list.length})
  }


 


  listItemSelected = (x) => { 

    if (this.state.showEditor == true) return false;

    var li = this.state.list[this.state.selectedIndex] != undefined ? document.getElementById("li" +  this.state.list[this.state.selectedIndex].id) : undefined
    var mi = this.state.list[this.state.selectedIndex] != undefined ? document.getElementById("more" + this.state.list[this.state.selectedIndex].id) : undefined

    var lin = document.getElementById("li" +  this.state.list[x].id);
    var min = document.getElementById("more" +  this.state.list[x].id);


    if ( li != undefined)
    { 
      li.style.color = "#404040"; li.style.backgroundColor = null; 
      mi.style.opacity=0; 
    }    
    this.setState({ selectedIndex : x })
    lin.style.color = "black"; lin.style.backgroundColor = "lightgrey"; min.style.opacity=0.7;     
    
    if (this.state.list[x].content != null){
      this.setState({ contentState : this.state.list[x].content})
      this.setState({html : draftToHtml(this.state.list[x].content).replace(/<p><\/p>/g,"<br/>")})
    }
    else
      this.setState({html : "", contentState : convertToRaw(EditorState.createEmpty().getCurrentContent())  })
  }


  handleEdit = (e,row) => {
    e.stopPropagation();
    this.setState({crudDialogOpen : true, modify_item : true, modify_item_data : row })
  }

  handleCrudDialogClose = () => {
    this.setState({crudDialogOpen : false, modify_item_data : {}, iconTag : null,})
  }


  crudObject = (opr,object_type,object_id,object_data) => { 

    if (opr == "edit")
    formLabels.map(x => { console.log(document.getElementById("form-" + x.id).value, x.id);
      var value = document.getElementById("form-" + x.id).value
      object_data[x.id] = value;
    })

    var data = { object_data : object_data, object_type : object_type, object_id : object_id, opr : opr};
    data.access_token = cookies.get('/user/access_token');
    
    var options = {   method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data) };
    let url = server + '/user/object/crud'
    fetch(url,options)
    .then(response => response.json())
    .then(jsondata => {
      if(jsondata.status == "success") { 
        this.fetchList(false); 
        this.setState({ crudDialogOpen : false })
      }
      else this.props.displaySM("Some Error Occured") 
    });
  }

  add = () => {
    blog_data.forEach(x => {
      console.log(x);
      this.crudObject("create","tb_blogs",null,{ title : x.title, h1 : x.h1, suburl : x.link.split("plumqr.com/")[1],author : x.name, meta : x.meta, picture : x.picture, sublabel : x.subLabel, weburl : "plumqr.com"  })
    })
  }




  render() {

  const { classes } = this.props;
  const { editorState,contentState, showEditor,html,list,selectedIndex } = this.state;
  var title = this.state.list.length > 0 ? (this.state.list[this.state.selectedIndex] != undefined ? this.state.list[this.state.selectedIndex].title : "" ) : "";

   return(
    <ThemeProvider theme={theme}>
    <Container>

    <Drawer variant="permanent" open={true}       classes={{ paper : classes.paper }} >
    <Typography style={{ paddingBottom : "2vh", paddingTop : "2vh", paddingLeft : "3vw"}} children="Blogs" variant="h3"/>
    <DrawerList handleEdit={this.handleEdit} createEditObject={this.createEditObject} list={list} addInList={this.addInList} classes={classes} listItemSelected={this.listItemSelected} selectedIndex={selectedIndex} />
    </Drawer>



    <Grid container direction="column" alignItems="flex-end" style={{marginLeft : "18vw", width : "70vw"}}>
    
    { !showEditor ?
      <Grid container  justify="space-between">
      <Button variant="contained" style={{ marginTop : "3vh", padding :"8px"}} children={"Check Published"} onClick={e => { window.open( window.location.origin + "/blogs/published?id=" + list[selectedIndex].id) }}/>
      <Button variant="contained" style={{ marginTop : "3vh", padding :"8px", width : "50px"}} children={"Edit"} onClick={e => this.setState({showEditor : true})}/>
      </Grid>
      :
      <Button variant="inline" style={{ marginTop : "3vh", padding : "8px",  width : "50px"}} children={"Save"} 
            onClick={e => { 
                            this.createEditObject('edit',list[selectedIndex].id,{ content : JSON.stringify(contentState) },true);
                            this.setState({html : draftToHtml(contentState).replace(/<p><\/p>/g,"<br/>")});
                            this.setState({showEditor : false}); 
                          } 
                    }
      />
    }


    <div style={{ height : "50vh", marginTop : "2vh", width : "70vw"  }} >
    <CrudDialog modify_item={true} modify_item_data={list.length > 0 ? list[selectedIndex] : {}} crudDialogOpen={this.state.crudDialogOpen} handleCrudDialogClose={this.handleCrudDialogClose} crudObject={this.crudObject} />

    { showEditor ? 
      <Editor
        initialContentState={contentState}
        onContentStateChange={this.onContentStateChange}
         toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
           embedded:{ embedCallback: embedVideoCallBack },
          image : { uploadCallback : uploadImageCallBack, previewImage : true }
        }}
      />
      :
      <div>
      <Box p={2} />
      <h1 style={{textAlign : "center"}} >{ title }</h1>
      <Box p={3} />
      <div style={{ color : "#393939", marginLeft : "30px", minHeight : "120vh"}}>
      { parse(html.replace(/<p /g,'<p style="margin : 30px 0px"') ) }
      </div>
      <Box p={6} />
      </div>
    }
    </div>
    </Grid>
    
    </Container>
    </ThemeProvider>

    );
  }
}

const BlogsW = withStyles(useStyles)(X);
export default BlogsW